import Timer from "../../controls/timer";
import Modal from "./modal";
import {useSelector} from "react-redux";

const Main = () => {
    const {timerDataState} = useSelector(state => state.timer);

    return (
        <div className={"container mx-auto lg:h-screen sm:h-auto grid row sm:grid-cols-1 lg:grid-cols-4 lg:gap-4 sm:gap-8 pb-12 pt-6"}>
            {timerDataState.map((item) => {
                return (
                    <Timer
                        id={item.id}
                        timeVip={item.timeVip}
                        timerIs={item.timerIs}
                        time={item.time}
                        userName={item.userName}
                        days={item.days}
                        hours={item.hours}
                        minutes={item.minutes}
                        seconds={item.seconds}
                        status={item.status}
                        key={item.id}
                    />
                )
            })}

            <Modal/>
        </div>
    )
}

export default Main;