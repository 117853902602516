import {useState, useEffect} from 'react'
import {Dialog, DialogBackdrop, DialogPanel} from '@headlessui/react';
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {closeModal} from "../../slice/modal";
import TimeService from "../../service/timer_service";

const Modal = () => {
    const [user, setUser] = useState({userName: "", time: ""});
    const {openModal, modalId} = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [timeVip, setTimeVip] = useState(false);

    const changeHandler = (e) => {

        let inputValue = e.target.value;
        inputValue = inputValue.replace(',', '.');

        setUser({
            ...user,
            [e.target.name]: inputValue
        })
    }

    useEffect(() => {
        if (openModal) {
            setOpen(true)
        }
    }, [openModal]);

    const closeModalController = () => {
        setOpen(false);
        dispatch(closeModal());
        setTimeVip(false);
        setUser({
            userName: "",
            time: ""
        })
    }

    const submitData = async (e) => {
        e.preventDefault();

        const students = {
            userName: user.userName,
            time: user.time * 60 * 60,
            timerId: modalId,
            isVip: timeVip
        }

        try {
            const response = await TimeService.createTimer(students);
            dispatch(closeModal());
            setOpen(false)
            setUser({
                userName: "",
                time: ""
            })
            setTimeVip(false);

        } catch (error) {
            console.log(error);
        }
    }

    const changeHandlerVip = () => {
        timeVip ? setTimeVip(false) : setTimeVip(true);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
                dispatch(closeModal());
                setTimeVip(false)
            }}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                    <DialogPanel
                        transition
                        className="relative lg:p-6 sm:p-8 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl  lg:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <h1 className="font-bold leading-tight tracking-tight text-gray-900 lg:text-2xl sm:text-5xl lg:mb-8 sm:mb-10 sm:leading-tight">
                            TATU elektron kutubxona foydalanuvchi ro'yhatdan o'tkazish
                        </h1>

                        <form className="space-y-6 lg:space-y-8 sm:space-y-20" onSubmit={submitData}>
                            <div>
                                <label htmlFor="user"
                                       className="block lg:mb-2 sm:mb-4 lg:text-sm sm:text-2xl font-medium text-gray-900">
                                    Talaba ma'lumoti
                                </label>
                                <input
                                    type="text"
                                    name="userName"
                                    onChange={changeHandler}
                                    id={"user"}
                                    value={user.userName}
                                    className={"bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full lg:p-2.5 sm:p-4 sm:text-4xl lg:text-sm"}
                                    placeholder={"Name"}
                                    autoComplete={"username"}
                                    required
                                />
                            </div>
                            {!timeVip ?
                                <div className={"mb-16"}>
                                    <label htmlFor={"number"}
                                           className={"block lg:mb-2 sm:mb-4 lg:text-sm sm:text-2xl font-medium text-gray-900 dark:text-white"}>
                                        Foydalanish vaqti (soatda)
                                    </label>
                                    <input
                                        type="number"
                                        name={"time"}
                                        onChange={changeHandler}
                                        id={"number"}
                                        placeholder={"123"}
                                        className={"bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full lg:p-2.5 sm:p-4 sm:text-4xl lg:text-sm"}
                                        value={user.time}
                                        step="any"
                                        required
                                    />
                                </div> : ""}

                            <div className={"mb-16 flex items-center"}>
                                <input
                                    type={"checkbox"}
                                    name={"timerVip"}
                                    id={"timeVip"}
                                    value={user.timerVip}
                                    onChange={changeHandlerVip}
                                    className={"lg:w-5 lg:h-5 sm:w-7 sm:h-7 text-blue-600 bg-gray-100 border-gray-300 rounded "}
                                />
                                <label htmlFor={"timeVip"}
                                       className={"font-medium text-gray-900 lg:ml-2 sm:ml-4 lg:text-sm sm:text-3xl"}>
                                    Vip tarifni yoqish
                                </label>

                            </div>

                            <div className={"grid grid-cols-2 gap-32"}>
                                <button onClick={closeModalController}
                                        className="w-full text-primary-600 border border-indigo-600 font-medium rounded-lg lg:text-sm sm:text-3xl lg:px-5 lg:py-2.5 sm:px-8 sm:py-4 text-center">Yopish
                                </button>
                                <button type="submit"
                                        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg lg:text-sm sm:text-3xl lg:px-5 lg:py-2.5 sm:px-8 sm:py-4 text-center">Boshlash
                                </button>
                            </div>

                        </form>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default Modal;