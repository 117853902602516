import axios from "./api";

const AuthService = {

    async userLogin(user) {
        const response = await axios.post('/login', user);
        return response
    },
    async getUser() {
        const response = await axios.get("/api/v1/users/getCurrentUser");
        return response
    },

}

export default AuthService;

