import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    timerDataState: [
        {
            id: 1,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 2,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 3,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 4,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 5,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 6,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 7,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 8,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 9,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 10,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 11,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 12,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },
        {
            id: 13,
            userName: "Free",
            status: false,
            time: 0,
            timeVip: 0,
            timerIs: false,
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
        },

    ]
}

export const timerSlice = createSlice({
    name: "timer",
    initialState,
    reducers: {
        timerWatching: (state, action) => {
            const {setData, id} = action.payload;
            const timer = state.timerDataState.find(timer => timer.id === id);
            if (timer) {
                timer.hours = setData.hours;
                timer.minutes = setData.minutes;
                timer.seconds = setData.seconds;
            }
        },

        getUpdateData: (state, action) => {
            const data = action.payload;
            data.map((item) => {
                const timer = state.timerDataState.find(timer => timer.id === item.id);
                if(timer) {

                    timer.userName = item.readerFullName
                    timer.time = item.remainingTime
                    timer.status = item.running
                    timer.timerIs = item.vip
                    timer.timeVip  = item.currentTime
                }

            })
        },

        socketGetData: (state, action) => {
            const data = action.payload;

            data.map((item) => {
                const timer = state.timerDataState.find(timer => timer.id === item.timerData.id);
                const timeSub = item.timerData

                if(timer) {
                    if(timeSub.currentMethod === "reset") {
                        timer.hours = "00"
                        timer.minutes = "00"
                        timer.seconds = "00"
                    }

                    timer.userName = timeSub.readerFullName
                    timer.time = timeSub.remainingTime
                    timer.status = timeSub.running
                    timer.timerIs = timeSub.vip
                    timer.timeVip = timeSub.currentTime
                }

            })
        },

    },

})

export const {
    timerWatching,
    getUpdateData,
    socketGetData

} = timerSlice.actions
export default timerSlice.reducer