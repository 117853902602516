import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/css/tailwind.css";
import store from "./store"
import {Provider} from "react-redux";
import "./assets/css/style.css";
import {BrowserRouter} from "react-router-dom";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);


