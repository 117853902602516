const getSekundomerDifference = (timeDifference) => {

    let days = Math.floor(timeDifference / (24 * 60 * 60 * 1000)).toString().padStart(2, '0');
    let hours = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)).toString().padStart(2, '0');
    let minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (1000 * 60)).toString().padStart(2, '0');
    let seconds = Math.floor((timeDifference % (60 * 1000)) / 1000).toString().padStart(2, '0');

    return { days, hours, minutes, seconds };
};

export default getSekundomerDifference;