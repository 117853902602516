import {configureStore} from '@reduxjs/toolkit';
import AuthReducer from "../slice/auth";
import TimerReducer from "../slice/timer";
import ModalReducer from "../slice/modal";

export default configureStore({
    reducer: {
        auth: AuthReducer,
        timer: TimerReducer,
        modal: ModalReducer,
    },
    devTools: process.env.NODE_ENV !== 'production'
})



