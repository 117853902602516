import TimerLayout from "./timerLayout";
import {useSelector} from "react-redux";

const TimerTv = () => {
    const {timerDataState} = useSelector(state => state.timer);
    return (
        <div>
            <div className={"timer_layout-tv bg-[#F9FAFB]"}>
                {timerDataState.map((item) => {
                    return (
                        <TimerLayout
                            id={item.id}
                            timeVip={item.timeVip}
                            timerIs={item.timerIs}
                            time={item.time}
                            status={item.status}
                            userName={item.userName}
                            days={item.days}
                            hours={item.hours}
                            minutes={item.minutes}
                            seconds={item.seconds}
                            key={item.id}
                        />
                    )
                })}
            </div>
        </div>
    )
}
export default TimerTv;