
import {createSlice} from "@reduxjs/toolkit";
import {setItem} from "../helpers/persistance-storage";

const initialState = {
    isLoading: false,
    loggedIn: false,
    userData: null,
    error: ""
}
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginUserStart: (state, action) => {
            state.isLoading = true
        },

        loginUserSuccess: (state, action) => {
            state.loggedIn = true
            state.isLoading = false
            setItem("token", action.payload.tokenId)
        },
        loginUserFailure: (state, action) => {
            state.isLoading = false
            state.error = "Login yoki parol noto'g'ri"
        },

        userAuthorization: (state, action) => {
            state.loggedIn = true
            state.isLoading = false
            state.userData = action.payload
        },
        logoutUser: (state, action) => {
            state.loggedIn = false
            state.userData = null
        }
    }
})
export const {
    loginUserStart,
    loginUserSuccess,
    loginUserFailure,
    userAuthorization,
    logoutUser
} = authSlice.actions
export default authSlice.reducer