import axios from "./api";

const TimeService = {

    async createTimer(data) {
        const response = await axios.post(`/timer/start-timer?id=${data.timerId}&readerFullName=${data.userName}&duration=${data.time}&isVip=${data.isVip}`);
        return response
    },

    async startTimer(id) {
        const response = await axios.post(`/timer/resume-timer?id=${id}`);
        return response
    },

    async stopTimer(id) {
        const response = await axios.post(`/timer/stop-timer?id=${id}`);
        return response
    },

    async resetTimer(id) {
        const response = await axios.post(`/timer/reset-timer?id=${id}`);
        return response
    },

    async getFullData() {
        const response = await axios.get("/timer/get-timers")
        return response;
    },
}

export default TimeService;