import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    openModal: false,
    modalId: 0,
}

export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.openModal = true
            state.modalId = action.payload
        },

        closeModal: (state, action) => {
            state.openModal = false
        },
    }
});

export const {
    openModal,
    closeModal,

} = modalSlice.actions;

export default modalSlice.reducer