import React from "react";
import Logo from "../../assets/img/logo.png";
import Footer from "../footer";
import Main from "../main"

import {removeItem} from "../../helpers/persistance-storage";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../slice/auth";

const Admin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const removeHandler = () => {
        removeItem("token");
        dispatch(logoutUser());
        navigate("/login")
    }

    return (
        <div>
            <nav className="border-gray-200 dark:bg-gray-900 bg-[#070732]">
                <div
                    className="sm:max-w-screen-xl lg:max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto lg:p-4 sm:px-9 sm:py-5">
                    <a href="#" className="lib_logo">
                        <img src={Logo} className="" alt="Flowbite Logo"/>
                        <span
                            className="self-center">
                            Muhammad al-Xorazmiy nomidagi <br/> Toshkent Axborot Texnologiyalari <br/> Universiteti
                        </span>
                    </a>

                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="header_btn">
                            <li>
                                <a href="#" className="block py-2 px-3 text-white lg:text-xl sm:text-4xl"
                                   aria-current="page">
                                    Admin
                                </a>
                            </li>
                            <li>
                                <button className={"btn_remove"} onClick={removeHandler}>chiqish</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <main>
                <Main/>
            </main>

            <Footer/>
        </div>
    )
}

export default Admin;