import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {loginUserStart} from "../../slice/auth";
import {loginUserSuccess} from "../../slice/auth";
import {loginUserFailure} from "../../slice/auth";
import AuthService from "../../service/auth";
import {useNavigate} from "react-router-dom";
const Login = () => {

    const {loggedIn, error} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    const changeHandler = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const submitData = async (e) => {
        e.preventDefault();
        dispatch(loginUserStart())

        const userData = {
           username: user.email,
           password: user.password
        }

        try {
            const response = await AuthService.userLogin(userData);
            dispatch(loginUserSuccess(response.data));
            navigate("/admin")

        } catch (error) {
            dispatch(loginUserFailure(error));
            console.log(error);
        }

        return () => {
            setUser({
                email: "",
                password: ""
            });
        };
    }

    useEffect(() => {
        if (loggedIn) {
            navigate("/admin")
        }
    }, [loggedIn]);

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 lg:max-w-md sm:max-w-4xl xl:p-0">
                    <div className="lg:p-6 lg:space-y-6 sm:space-y-20 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 lg:text-2xl sm:text-5xl">
                            TATU elektron kutubxona admin sahifasiga kirish
                        </h1>

                        <p className={"text-center text-danger"}> {error}</p>
                        <form className="lg:space-y-10 sm:space-y-20" onSubmit={submitData}>
                            <div>
                                <label htmlFor="email" className="block lg:mb-2 sm:mb-5 lg:text-sm sm:text-3xl font-medium text-gray-900">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    onChange={changeHandler}
                                    id={"email"}
                                    value={user.email}
                                    className={"bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full lg:text-[16px] sm:text-4xl lg:p-0 lg:px-2 sm:p-5"}
                                    placeholder={"name@company.com"}
                                    autoComplete={"username"}
                                    required
                                />
                            </div>
                            <div className={"mb-16"}>
                                <label htmlFor={"password"}
                                       className={"block lg:mb-2 sm:mb-5 lg:text-sm sm:text-3xl font-medium text-gray-900 dark:text-white"}>
                                    Parol
                                </label>
                                <input
                                    type="password"
                                    name={"password"}
                                    onChange={changeHandler}
                                    id={"password"}
                                    placeholder={"••••••••"}
                                    className={"bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  lg:text-[16px] sm:text-4xl lg:p-0 lg:px-2 sm:p-4"}
                                    autoComplete={"current-password"}
                                    value={user.password}
                                    required
                                />
                            </div>

                            <button type="submit"
                                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg lg:text-sm sm:text-4xl lg:px-5 sm:px-10 lg:py-2.5 sm:py-5 text-center">Kirish
                            </button>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Login;