

const Footer = () => {
    return (
        <footer className="bg-[#070732]">
            <div className="w-full mx-auto max-w-screen-xl lg:p-4 sm:p-8 footer_container">

                <ul className="footer_list lg:text-sm sm:text-3xl font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="https://tuit.uz/" className="hover:underline me-4 md:me-6">TUIT.UZ</a>
                    </li>
                    <li>
                        <a href="https://unilibrary.uz/" className="hover:underline me-4 md:me-6">UNILIBRARY.UZ</a>
                    </li>
                </ul>

                <span className="lg:text-sm sm:text-3xl text-gray-500 sm:text-center dark:text-gray-400">
                        Copyright © 2024 TUIT RIRM by Sanjar, Ozodbek
                </span>
            </div>
        </footer>
    )
}

export default Footer;