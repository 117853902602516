import {Routes, Route, Navigate} from "react-router-dom";
import routes from "./rootes";
import AuthService from "./service/auth";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {userAuthorization} from "./slice/auth";
import {getItem} from "./helpers/persistance-storage";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import TimeService from "./service/timer_service";
import {getUpdateData} from "./slice/timer";
import {socketGetData} from "./slice/timer";
import {useRef} from "react";

function App() {
    const {loggedIn} = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const pathArr = currentPath.split('/');
    const reconnectTimeoutRef = useRef(null);
    const socketRef = useRef(null);

    const getUser = async () => {
        try {
            const response = await AuthService.getUser()
            dispatch(userAuthorization(response.data))
        } catch (error) {
            console.log(error)
        }
    }

    const getTimerData = async () => {
        try {
            const response = await TimeService.getFullData();
            dispatch(getUpdateData(response.data))
        } catch (error) {
            console.log(error);
        }
    }

    const connectWebSocket = () => {
        // ws://localhost:8085/ws/timer
        // wss://api.lib.tuit.uz/ws/timer
        socketRef.current = new WebSocket('wss://api.lib.tuit.uz/ws/timer');

        socketRef.current.onopen = () => {
            console.log('WebSocket connection established');
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        socketRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socketRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:');
            reconnectWebSocket();
        };

        socketRef.current.onmessage = (event) => {
            try {
                const timers = JSON.parse(event.data);
                const updatedCards = Object.keys(timers).map(id => ({
                    id,
                    timerData: timers[id],
                }));
                dispatch(socketGetData(updatedCards));
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };
    };

    const reconnectWebSocket = () => {
        reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
        }, 5000);
    };
    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.onclose = null;
                socketRef.current.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, []);


    useEffect(() => {
        getTimerData()
    }, []);

    useEffect(() => {
        const token = getItem("token");
        if (token) {
            getUser()
        }
        if (!loggedIn) {
            if (pathArr[1] === "admin") {
                navigate("/login");
            }
        }
    }, []);


    return (
        <div>
            <Routes>
                {routes.map(
                    ({path, element}, key) =>
                        element && <Route key={key} exact path={path} element={element}/>
                )}
                <Route path="*" element={<Navigate to="" replace/>}/>

            </Routes>
        </div>
    );
}

export default App;
