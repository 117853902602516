import Timers from "./pages/Timers";
import Admin from "./pages/admin";
import Login from "./pages/login";
import TimersTv from "./pages/Timers/timersTv";

export const routes = [
    {
        name: "Home",
        type: "collapse",
        path: "",
        element: <Timers/>
    },
    {
        name: "Admin",
        type: "collapse",
        path: "/admin",
        element: <Admin/>
    },
    {
        name: "Login",
        type: "auth",
        path: "/login",
        element: <Login/>
    },
    {
        name: "TV",
        type: "collapse",
        path: "/tv",
        element: <TimersTv/>
    }
];

export default routes;