import {useDispatch} from "react-redux";
import {useCallback, useEffect, useRef} from "react";
import {timerWatching} from "../../slice/timer";
import getTimeDifference from "../../controls/timerController";
import getSekundomerDifference from "../../controls/sekundamerController";

const TimerLayout = (props) => {
    const dispatch = useDispatch();
    const {id, userName, hours, minutes, seconds, time, status, timerIs, timeVip} = props;
    const timerRef = useRef();
    const secondRef = useRef()

    const updateSecond = (time) => {
        const {days, hours, minutes, seconds} = getSekundomerDifference(time);

        const setData = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        };

        dispatch(timerWatching({setData, id}));

    }

    const updateTimer = (time) => {

        const {days, hours, minutes, seconds, timeDifference} = getTimeDifference(time);

        const setData = {
            days: timeDifference < 0 ? "00" : days,
            hours: timeDifference < 0 ? "00" : hours,
            minutes: timeDifference < 0 ? "00" : minutes,
            seconds: timeDifference < 0 ? "00" : seconds,
        };

        dispatch(timerWatching({setData, id}));

        if (timeDifference < 0) {
            clearInterval(timerRef.current);
        }
    };


    const startSecondDown = useCallback((time) => {

        let timeStart = time
        secondRef.current = setInterval(() => {
            timeStart = timeStart + 1
            updateSecond(timeStart * 1000);

        }, 1000);

        return () => clearInterval(secondRef.current);

    }, []);

    const startCountDown = useCallback((time) => {
        const countDownDate = new Date().getTime() + time * 1000;

        timerRef.current = setInterval(() => {
            updateTimer(countDownDate);
        }, 1000);

        return () => clearInterval(timerRef.current);

    }, []);

    const stopCountDownSecond = useCallback(() => {
        clearInterval(secondRef.current);
    }, []);

    const stopCountDownTime = useCallback(() => {
        clearInterval(timerRef.current);
    }, []);

    useEffect(() => {

        if (status && !timerIs) {
            startCountDown(time);
            return () => clearInterval(timerRef.current);
        }

        if (timerIs && status) {
            startSecondDown(timeVip)
            return () => clearInterval(secondRef.current);
        }
    }, [time, status, timeVip]);


    useEffect(() => {
        if (!status) {
            stopCountDownTime()
            stopCountDownSecond()
        }
    }, [status]);


    return (
        <div className={`bg-[#FFFFFF] py-6 shadow-2xl ${!status ? "free" : "active_students"}`}>
            <div className="flex flex-col items-center justify-center lg:gap-2 sm:gap-4">

                <div className="font-semibold text-[#333333] text-center tracking-widest">
                    <span className={"block lg:text-3xl sm:text-4xl sm:mb-2 lg:mb-0 sm:font-bold"}> {id}</span>
                    <span className={"sm:text-3xl lg:text-xl"}>{userName}</span>
                </div>

                <div className="flex justify-center lg:gap-2 sm:gap-7">

                    <div className="flex flex-col gap-1 relative">
                        <div
                            className="sm:w-24 sm:h-24 lg:w-14 lg:h-14 flex justify-between items-center bg-[#E0F7FA] rounded-lg">
                            <div
                                className="relative h-2.5 w-2.5 !-left-[6px] rounded-full bg-[white]"></div>
                            <span className="sm:text-5xl lg:text-4xl font-semibold text-[#00796B]">
                                   {hours}
                                 </span>
                            <div
                                className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[white]"></div>
                        </div>
                        <span className="text-[#00796B] text-center capitalize text-sm">
                              {hours === 1 ? "Hour" : "Hours"}
                          </span>
                    </div>

                    <div className="flex flex-col gap-1 relative">
                        <div
                            className="sm:w-24 sm:h-24 lg:w-14 lg:h-14 flex justify-between items-center bg-[#E0F7FA] rounded-lg">
                            <div
                                className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[white]"></div>
                            <span className="sm:text-5xl lg:text-4xl font-semibold text-[#00796B]">
                                 {minutes}
                                 </span>
                            <div
                                className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[white]"></div>
                        </div>
                        <span className="text-[#00796B] text-center capitalize text-sm">
                              {minutes === 1 ? "Minute" : "Minutes"}
                          </span>
                    </div>

                    <div className="flex flex-col gap-1 relative">
                        <div
                            className="sm:w-24 sm:h-24 lg:w-14 lg:h-14 flex justify-between items-center bg-[#E0F7FA] rounded-lg">
                            <div
                                className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[white]"></div>
                            <span className="sm:text-5xl lg:text-4xl font-semibold text-[#00796B] text-sm">
                                 {seconds}
                                 </span>
                            <div
                                className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[white]"></div>
                        </div>
                        <span className="text-[#00796B] text-center capitalize text-sm">
                              {seconds === 1 ? "Second" : "Seconds"}
                          </span>
                    </div>

                </div>
            </div>
        </div>
    )
};
export default TimerLayout;