import {useDispatch} from "react-redux";
import {openModal} from "../slice/modal";
import TimerService from "../service/timer_service";
import {useCallback, useEffect, useRef} from "react";
import {timerWatching} from "../slice/timer";
import getTimeDifference from "./timerController";
import getSekundomerDifference from "./sekundamerController";

const Timer = (props) => {

    const dispatch = useDispatch();
    const {id, userName, hours, minutes, seconds, time, status, timerIs, timeVip} = props;
    const timerRef = useRef();
    const secondRef = useRef()

    const updateSecond = (time) => {
        const {days, hours, minutes, seconds} = getSekundomerDifference(time);
        const setData = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        };
        dispatch(timerWatching({setData, id}));
    }

    const updateTimer = (time) => {

        const {days, hours, minutes, seconds, timeDifference} = getTimeDifference(time);
        const setData = {
            days: timeDifference < 0 ? "00" : days,
            hours: timeDifference < 0 ? "00" : hours,
            minutes: timeDifference < 0 ? "00" : minutes,
            seconds: timeDifference < 0 ? "00" : seconds,
        };

        dispatch(timerWatching({setData, id}));

        if (timeDifference < 0) {
            clearInterval(timerRef.current);
        }
    };

    const startSecondDown = useCallback((time) => {

        let timeStart = time
        secondRef.current = setInterval(() => {
            timeStart = timeStart + 1
            updateSecond(timeStart * 1000);

        }, 1000);

        return () => clearInterval(secondRef.current);

    }, []);

    const startCountDown = useCallback((time) => {
        const countDownDate = new Date().getTime() + time * 1000;

        timerRef.current = setInterval(() => {
            updateTimer(countDownDate);
        }, 1000);

        return () => clearInterval(timerRef.current);

    }, []);

    const stopCountDownSecond = useCallback(() => {
        clearInterval(secondRef.current);
    }, []);

    const stopCountDownTime = useCallback(() => {
        clearInterval(timerRef.current);
    }, []);

    useEffect(() => {

        if (status && !timerIs) {
            startCountDown(time);
            return () => clearInterval(timerRef.current);
        }

        if (timerIs && status) {
            startSecondDown(timeVip)
            return () => clearInterval(secondRef.current);
        }
    }, [time, status, timeVip]);


    useEffect(() => {
        if (!status) {
            stopCountDownTime()
            stopCountDownSecond()
        }
    }, [status]);

    // ---------------------------------------------------------------------------------------

    const openModalHandler = (cardNumber) => {
        dispatch(openModal(cardNumber))
    }
    const startHandler = async () => {

        try {
            const response = await TimerService.startTimer(id)
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    const stopHAndler = async () => {
        try {
            const response = await TimerService.stopTimer(id);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    const resetHandler = async (e) => {
        try {
            const response = await TimerService.resetTimer(id);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className="bg-blue-100 border-r-4 shadow-2xl lg:p-4 sm:px-6 sm:py-12">
                <div className={"card-head admin_timer-card"}>
                    <h2 className={"lg:text-2xl sm:text-6xl"}>{id}</h2>
                    <span className={"timer lg:text-2xl sm:text-5xl"}>
                   {hours}:{minutes}:{seconds}
                </span>
                </div>

                <div className={"card-body"}>
                    <h1 className={"text-gray-600 p-4 text-center lg:text-2xl sm:text-6xl"}>{userName}</h1>
                </div>
                <div className={"card-footer text-center"}>
                    <button type="button" onClick={() => openModalHandler(props.id)}
                            className="lg:py-2.5 sm:py-4 lg:px-5 sm:px-8 lg:me-4 sm:me-8 mb-2 lg:text-sm sm:text-4xl font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Boshlash
                    </button>
                    <button type="button" onClick={stopHAndler} disabled={userName === "Free" ? true : false}
                            className="lg:py-2.5 sm:py-4 lg:px-5 sm:px-8 lg:me-4 sm:me-8 mb-2 lg:text-sm sm:text-4xl font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        {/*{status ? "Stop" : "Start"}*/} Stop
                    </button>
                    <button type="button" onClick={resetHandler}
                            className="lg:py-2.5 sm:py-4 lg:px-5 sm:px-8 lg:me-4 sm:me-8 mb-2 lg:text-sm sm:text-4xl font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Tozalash
                    </button>
                </div>

            </div>
        </div>
    )
};

export default Timer;
