import axios from "axios";
import {getItem} from "../helpers/persistance-storage";

// http://localhost:8085/
//https://api.lib.tuit.uz/
axios.defaults.baseURL = "https://api.lib.tuit.uz/"

axios.interceptors.request.use(config=> {
    const token = getItem("token");
    const authorization = token ? `Bearer ${token}` : "";
    config.headers.Authorization = authorization
    return config
})
export default axios;